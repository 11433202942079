import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js";

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Get the remaining time string. Returns the remaining time string in the format of `x units away`.
 * Units can be `day`, `week`, or `month`
 * If the remaining time is more than 30 days, unit is `month`. If more than 7 days, unit is `week`. Otherwise, unit is `day`.
 * @param date date string to compare with the current date
 * @returns the remaining time string in the format of `x units away`.
 */
export function getRemainingTimeString(date: string) {
  const diff = dayjs(date).diff(dayjs(), "day");

  let remaining = diff;
  let unit = "day";

  if (diff > 30) {
    remaining = Math.floor(diff / 30);
    unit = "month";
  } else if (diff > 13) {
    remaining = Math.floor(diff / 7);
    unit = "week";
  }

  return `${remaining} ${unit}${remaining > 1 ? "s" : ""} away`;
}

export function isTodayOrFutureDate(date: string | Date): boolean {
  const today = dayjs.utc().startOf("day"); // set time to midnight UTC
  const inputDate = dayjs.utc(date).startOf("day"); // set time input date to midnight UTC

  return inputDate.isSame(today) || inputDate.isAfter(today);
}

export function getCurrentYear(): number {
  return dayjs.utc().year();
}
